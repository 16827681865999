@import "./../../../assets/styles/index";


.formWrapper {
  max-width: 460px;
}

.form__button {
  margin-top: 50px;
}

.form__subtitle {
  font-weight: bold;
  text-align: right;
  margin: 30px 0 35px;
  & > .subtitleErrrorText{
    font-size: 14px;
    color: #d32f2f;
}
}

.form__spreadBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form__submitButton {
  max-height: 43px;
  margin-left: auto;
  display: block;

  &:hover {
    background-color: #fff;
    color: $turquoise
  }
}
.form__cancelButton {
  max-height: 43px;
  margin-right: 10px;
  &:hover {
    background-color: $turquoise;
    color: #fff;
  }
}
