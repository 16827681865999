.appTitle {
  width: 100%;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 30px;
}

.appTitle__right {
  text-align: right;
}

.appTitle__left {
  text-align: left;
}