@import "../../../assets/styles/shared-variables";

.headerContent {
  background-color: rgba(255, 255, 255, 0.86);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  height: $headerHeight;
  padding: 0 5px;
  display: flex;
  align-items: center;

  .logo {
    object-fit: contain;
    height: 62%;
    display: inline-block;
    margin-left: 10px;
  }
  .userDetailsWrapper {
    flex: 1;

    .logout {
      transform: rotate(180deg);
    }
  }
}
