.autoCompleteWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.autoComplete {
  & .MuiInputBase-root {
    padding-right: 0 !important;
    padding-left: 30px;
    flex-direction: row-reverse;
  }

  & .MuiInputBase-root.MuiInputBase-adornedStart {
    padding-left: 56px;
  }

  & .MuiChip-deleteIcon {
    margin: 0 !important;
    margin-left: 5px !important;
  }

  & .MuiAutocomplete-endAdornment {
    right: initial !important;
    left: 0;
    display: flex;
    flex-direction: row-reverse;
  }
}

.MuiAutocomplete-popper {
  & > div {
    display: flex;
    flex-direction: row-reverse;

    & > * {
      width: 100%;

      & > * {
        display: flex;
        padding-right: 5px !important;
      }
    }
  }
}
