@import "../../../assets/styles/colors.module";

.manageCities__addCompoundButton {
  margin-bottom: 15px;
  margin-left: auto;
  display: block;
}

.manageCities__actionsWrapper {
  max-width: 1100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.manageCities__sortInput {
  width: 200px !important;
}

.manageCities__compoundWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  -webkit-box-shadow: 5px 5px 14px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 14px 1px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 10px;
  max-width: 1100px;
  margin-bottom: 15px;
}

.manageCities__form {
  max-width: initial;
  flex: 1;

  & > form {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > * {
      max-width: 181px;
      min-width: 151px;
      width: initial !important;
    }

    & > div:first-child {
      flex: 0.8;
    }

    & > label:first-child {
      margin-right: 2px;
      min-width: 165px !important;
      justify-content: flex-end;
    }

    & > .form__submitButton {
      margin: 0 10px;
    }
    & > .manageCities__submitButton {
      margin: 0 10px;
      min-width: initial;
    }
  }
}

.manageCities__dialog {
  & > .MuiDialog-container {
    & > .MuiPaper-root {
      min-width: 500px !important;
      max-width: 500px !important;
    }
  }
}
