@import "../../../assets/styles/colors.module";

.manageFwVersions {
  &__addCompoundButton {
    margin-bottom: 1rem;
    margin-left: auto;
    display: block;
  }

  &__compoundWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    box-shadow: 0.3125rem 0.3125rem 0.875rem 0.0625rem rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 0.625rem;
    max-width: 93.75rem;
    margin-bottom: 1rem;
  }

  &__form {
    max-width: initial;
    flex: 1;

    & > form {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.625rem;
      flex-wrap: nowrap;

      & > * {
        max-width: 8.9375rem;
        min-width: 8.5625rem;
        width: initial;
        justify-content: flex-end;
      }

      & > fieldset > label {
        margin-right: 0;
        margin-left: 0;
      }

      & > label:first-child {
        margin-right: 0.125rem;
        min-width: 10.3125rem;
      }

      & > div:first-child {
        flex: 0.8;
      }

      & > .MuiTextField-root {
        margin-left: 1.875rem;
      }

      & > .form__submitButton,
      & > .manageCells__submitButton {
        margin: 0 0.625rem;
        min-width: initial;
      }
    }
  }

  &__dialog > .MuiDialog-container > .MuiPaper-root {
    min-width: 31.25rem;
    max-width: 31.25rem;
  }

  &__component {
    margin-left: 0.4375rem;
  }
}