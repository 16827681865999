.optionFormWrapper {
  max-width: 100%;
  width: 100%;
  border: solid 1px black;
  margin-top: 25px;
  padding: 10px 30px;
  & > form {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > button {
      margin: unset !important;
    }
  }

}

.optionItemForm {
  display: flex;
  align-items: center;
  flex: 0 0 84%;
}

.optionItem__formItem {
  flex: 0 0 30%;
  padding-left: 40px !important;
}

.optionItem__checkboxItem {
  flex: 0 0 15%;
  display: inline-block !important;
  padding-right: 0 !important;
}
