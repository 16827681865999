@import "../../../assets/styles/colors.module";

.manageCompounds__addCompoundButton {
  margin-bottom: 15px;
  max-width: 850px;
  justify-content: flex-end !important;
  display: block;
}

.manageCompounds__compoundWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  -webkit-box-shadow: 5px 5px 14px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 14px 1px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 10px;
  max-width: 920px;
  margin-bottom: 15px;
}

.manageCompounds__form {
  max-width: initial;
  flex: 1;

  &>form {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &>* {
      max-width: 180px;
      min-width: 120px;
      width: initial !important;
    }

    &>div:first-child {
      flex: 0.8;
    }

    &>.manageCompounds__submitButton {
      margin: 0 10px;
      min-width: initial;
    }
  }
}

.manageCompounds__dialog {
  &>.MuiDialog-container {
    &>.MuiPaper-root {
      min-width: 650px !important;
      max-width: 650px !important;
      min-height: 290px !important;
      max-height: 290px !important;

      &>div {
        border-bottom: none !important;

        &>.autoCompleteWrapper {
          flex-direction: column-reverse !important;
        }
      }
    }
  }
}

.manageCompounds__emptyActionsColumn {
  width: 64px;
}

.manageCompounds__wrapperButton {
  max-width:920px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}