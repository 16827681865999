.manageSites__wrapperFormDetails {


    &>form {
        &>.form__submitButton {
            display: inline-block !important;
            flex: right;
        }


    }
}


.manageSites__dialog {
    &>.MuiDialog-container {
        &>.MuiPaper-root {
            min-width:550px !important;
            max-width: 750px !important;
            min-height: 290px !important;
            max-height: 590px !important;
        }
    }
}

.manageSites__checkBox {
    &>label {
        margin-right: 0px !important;
        &>span {

            padding-right: 0px !important
        }
    }

}

.sitesList{
    .tableContainer{
      max-height: calc(100vh - 400px);
    }
  }