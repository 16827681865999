.siteDetails__additionalDetails {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  & > .siteDetails__additionalDetail {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    & > .siteDetails__additionalDetail-label {
      width: 200px;
      text-align: right;
    }
  }
}

.siteDetails__isActiveSwitch,
.siteDetails__allowPPUSwitch {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 10px !important;

  & > .MuiFormControlLabel-root > .MuiSwitch-root {
    margin-right: -10px;
  }
}

.siteDetails__textField {
  margin-top: 15px !important;
}

.siteDetails__agreement_period {
  margin-top: 0;
  font-weight: 500;
  font-size: 0.8rem;
  margin-bottom: 10px;
}

.siteDetails__agreementPeriodDateWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  & > .div {
    flex: 0.48;
  }
}

.siteDetails__cityWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  & > .siteDetails__district {
    position: absolute;
    left: -150px;
    cursor: pointer;
  }

  & > .disable {
    cursor: not-allowed;
    color: gray;
  }
}
