.MuiCalendarPicker-root {
  direction: ltr !important;

  & * {
    direction: ltr !important;
  }
}

.timePicker{
  margin-top: 20px !important;
}

.datePicker{
  direction: rtl !important;
  .MuiInput-input{
    text-align: right;
  }
}