.legend {
  margin-top: 90px;
  width: 100%;
}

.legend__title {
  font-weight: 600;
  margin-bottom: 15px;
  text-align: right;
  padding-right: 8px;
}