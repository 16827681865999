@import "../../../assets/styles/shared-variables";
.mainContentWrapper {
  background-repeat: no-repeat;
  padding-top: $headerHeight;
  padding-right: $sidebarWidth;
  .mainPageWrapper {
    width: 95%;
    margin: 15px auto 0;

    @media print {
      width: 100%;
      margin: 0;
    }
  }

  @media print {
    padding: 0;
  }
}
