@import "../../../assets/styles/colors.module";

.manageCells__addCompoundButton {
  margin-bottom: 15px;
  margin-left: auto;
  display: block;
}

.manageCells__compoundWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  -webkit-box-shadow: 5px 5px 14px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 14px 1px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 10px;
  max-width: 1110px;
  margin-bottom: 15px;
}

.manageCells__form {
  max-width: initial;
  flex: 1;

  & > form {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > * {
      max-width: 143px;
      min-width: 137px;
      width: initial !important;
      justify-content: flex-end;
    }
    & > fieldset {
      & > label {
        margin-right: 0px !important;
        margin-left: 0px !important;
      }
    }

    & > label:first-child {
      margin-right: 2px;
      min-width: 165px;
    }

    & > div:first-child {
      flex: 0.8;
    }

    & > .MuiTextField-root{
      margin-left: 30px;
    }

    & > .form__submitButton {
      margin: 0 10px;
    }
    & > .manageCells__submitButton {
      margin: 0 10px;
      min-width: initial;
    }
  }
}

.manageCells__dialog {
  & > .MuiDialog-container {
    & > .MuiPaper-root {
      min-width: 500px !important;
      max-width: 500px !important;
    }
  }
}

.manageCells__component {
  margin-left: 7px;
}

.manageCells__button {
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
  margin-bottom: 15px;
  max-width: 1110px;
}
