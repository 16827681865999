// @import "../../../../assets/styles/index";


.customerDetails__phoneWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &>.customerDetails__phoneNumber {
    flex: 0.7;
  }

  &>.customerDetails__phoneExt {
    flex: 0.2;
    max-width: 130px;
  }
}

.firstLogin__submitButton {
  margin-left: initial !important;
  display: initial !important;
  margin-top: 15px;
}


.firstLogin__wrapper {

  &>.appTitle {
    text-align: center;
    margin: 0px !important
  }
  &>form {
    &>.form__subtitle {
      text-align: center;
      margin: 0px !important;
      font-weight: 100 !important;
    }
  }

  .login__submitButton {
    margin-left: initial !important;
    display: initial !important;
    margin-top: 15px;
  }
}

.firstLogin_passwordInstructions {
  text-align: right;
  font-size: small;
  font-weight: 600;
}

.firstLogin__title {
  text-align: center;
  margin-bottom: 0px !important
}

.firstLogin__subtitle {
  text-align: center;
  margin-top: 0px !important
}
.firstLogin__errorMessage{
  margin-top: 20px;
  color: red;
}