@import "../../../assets/styles/shared-variables";
$sidebarBoxShadow: 0 9px 13px 0 rgba(0, 0, 0, 0.1);

.sidebarWrapper {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: $sidebarWidth;
  padding-top: $headerHeight;
  z-index: 9999;

  @media print {
    display: none;
  }

  .sidebarMain {
    height: 100%;
    box-shadow: $sidebarBoxShadow;
    position: relative;
    z-index: 2;
    .menuIcon {
      color: $turquoise;
    }
    .rootMenuWrapper {
      height: 100%;
      .arrowWrapper {
        margin-top: 10px;
        margin-bottom: 10px;
        .btnIcon {
          padding: 10px 25px;
          border-radius: 40px;
          border: 2px solid $gray7;
        }
      }
      .sidebarRootItems {
        overflow: hidden;
        scroll-behavior: smooth;
        flex-grow: 1;
        width: 100%;
        .sidebarMenuRootItem {
          display: flex;
          height: 70px;
          width: 100%;
          .sidebarMenuRootItemLink {
            text-decoration: none;
            width: 100%;
            height: 100%;
            cursor: pointer;
            .parentMenuWrapper {
              width: 100%;
              height: 100%;
            }
            &:hover {
              background-color: $gray10;
            }
            .nameWrapper {
              font-size: 14px;
              color: $turquoise;
            }
            &.selected,
            &.selected:hover {
              background-color: $turquoise;
              .nameWrapper {
                color: $white;
              }
            }
            &.selected > * {
              color: $white;
             
              .menuIcon {
                color: $white;
              }
            }
            .menuImg {
              width: 28px;
              height: 28px;
              background-repeat: no-repeat;
              background-size: 28px 28px;
            }
          }
        }
      }
    }
    .subMenuWrapper {
      z-index: 2;
      position: absolute;
      top: 0;
      right: #{$sidebarWidth};
      width: 230px;
      box-shadow: $sidebarBoxShadow;
      padding-top: 10px;
      height: 100%;
      background-color: white;
      opacity: 0.95;
      .childMenuItem {
        color: $turquoise;
        display: block;
        padding: 10px 25px;
        text-decoration: none;
        &:hover {
          background-color: $gray10;
        }
        &.selected,
        &.selected:hover {
          background-color: $turquoise;
          color: $white;
        }
      }
    }
  }
}
