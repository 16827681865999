.orderMessagesHistory__title {
    max-width: fit-content !important
}

.wrapper__title {
    display: flex;
}

.wrapper__subTitle {
    margin-right: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 9px;
}

.orderMessagesList{
    .tableContainer{
      max-height: calc(100vh - 260px);
    }
    .MuiTableCell-alignRight{
      padding: 16px 16px 16px 16px !important;
    }
  
    .MuiTableCell-stickyHeader{
      padding-right: 16px !important;
    }
  }