.manageWaitingLists__siteOpeningDialog {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  .MuiPaper-root {
    max-width: 100% !important;
    width: 100%;
  }
}


.manageWaitingListTable{
  .tableContainer{
    max-height: calc(100vh - 450px);
  }
}