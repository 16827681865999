@import "../../../assets/styles/colors.module";

// make the arrows on input type fields hide
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.manageOrders__orderDate {
  flex: 2 !important;
}

.tableWrapper .MuiDataGrid-columnHeader:not(:first-child) {
  min-width: 200px !important;
}

.manageOrders__dotStatus {
  flex: initial !important;
  min-width: 40px !important;
  width: 40px !important;
}


.manageOrders__extendedContentWrapper {
  display: flex;
  flex-direction: column;
  padding-right: 34px;

  & * {
    display: flex;
    align-items: center;
  }

  & .manageOrders__extendedContent {
    margin-bottom: 15px;
  }

  & .manageOrders__extendedContentTitle {
    font-weight: 600;
    width: 130px;
    margin-left: 20px;
  }

  & .manageOrders__extendedContentDataWrapper {
    margin-left: 25px;
    min-width: 170px;
  }

  & .manageOrders__extendedContentLabel {
    margin-left: 10px;
  }
}


.manageorders__details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  & > .manageorders__city {
    flex: 0.25;
  }

  & > .manageorders__site {
    flex: 0.4;
    max-width: 130px;
  }

  & > .manageorders__compound {
    flex: 0.21 1;
    max-width: 130px;
  }
}

.manageorders__submitButton {
  align-items: flex-end;
}

.manageorders__wrapperFormDetails {
  & > form {
    & > .form__submitButton {
      display: inline-block !important;
      flex: right;
    }
  }
}

.manageorders__dialog {
  & > .MuiDialog-container {
    & > .MuiPaper-root {
      & > .MuiDialogContent-root {
        direction: rtl;
        & > .formWrapper {
          max-width: 500px !important;
        }
      }

      & > .MuiDialogTitle-root {
        direction: rtl;
      }

      min-width: 550px !important;
      max-width: 750px !important;
      min-height: 290px !important;
      max-height: 590px !important;
    }
  }
}

.lockerReleaseConfirm__dialog {
  & > .MuiPaper-root {
    & > .MuiDialogContent-root {
      direction: rtl;
      & > .formWrapper {
        max-width: 500px !important;
      }
    }

    & > .MuiDialogTitle-root {
      direction: rtl;
    }

    min-width: 350px !important;
    max-width: 350px !important;
    min-height: 240px !important;
    max-height: 240px !important;
  }
}


.manageorders__noteInactiveOrder {
  margin-right: 10px !important;
  color: red;
  text-align: right;
}

.messageToOrdersBody {
  width: 500px;
  @media(max-width: 600px) {
    width: 350px;
  }
  .sendMessageValidationText {
    font-size: 14px;
    color: red;

  }
}

.manageorders__cancelOrderForm {
  & > form {
    & > .form__submitButton {
      display: inline-block !important;
    }

    & > .form__cancelButton {
      background-color: $gray3 !important;
      border-color: $gray3 !important;
      color:$white
    }

    & > .buttonComponent {
      float: left !important;
    }
  }
}

.manageorders__disableMoreDetails {
  color: gray;
  opacity: 0.4;
}
.manageorders__disableMoreDetails:hover {
  color: gray;
  opacity: 0.4;
  cursor: default;
}

.manageorders__requiredFiels{
  color: red;
  font-size: 13px;
}

.tableWrapper.tableWrapper__withCheckboxes__showDots {
  .MuiDataGrid-columnHeaderCheckbox{
    margin-right: 3px;
    transform: translateX(-10px);
  }
}

.manageOrders{
  .tableContainer{
    max-height: calc(100vh - 380px);
  }
}
