.paginationWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  direction: ltr !important;

  & > div {
    flex: 0.33;
  }
}

.pagination {
  justify-content: center;
  min-width: fit-content !important;
}

.pagination__totalRows {
  text-align: right;
  padding-right: 15px;
}
