@import "../styles/shared-variables";

//btn
.btn {
  border-radius: 90px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 1;
  padding: 12px 14px;
  &.btn-primary,
  &.btn-primary:not(:disabled):not(.disabled):active{
    background-color: $white;
    box-shadow: 0 9px 13px 0 rgba(0, 0, 0, .06);
    color: $turquoise;
    font-weight: 500;
    border: 1.39px solid $turquoise;
  }
  &.btn-full{
    background-color: $turquoise;
    color: $white;

  }
  &.btn-l{
    padding: 12px 50px;
  }
  &.btn-danger {
    width: 88px;
  }

  &.btn-green{
    background-color: $green;
    color: $white;
  }

  &.image-button {
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    box-shadow: none;
    @include no-outline;
  }
}

.btn-link {
  border: none;
  background-color: transparent;
  color: $gray2;
  font-size: 15px;
  text-decoration: underline;
}

.btn-icon, .btn-text {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  @include no-outline;
}
.btn-text {
  color: $gray3;
  font-size: 15px;
  text-decoration: underline;
}
.btn-text-link {
  color: $turquoise;
  padding: 0;
  text-decoration: underline;
  &:hover {
    color: $turquoise;
    text-decoration: underline;
  }
}

.btn-text-icon {
  img {
    margin-left: 8px;
  }
  span {}
}

.paging-button {
  @include no-outline;
}
