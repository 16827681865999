.updateStatus__dialog {
    &>.MuiDialog-container {
      &>.MuiPaper-root {
        min-width: 650px !important;
        max-width: 650px !important;
        min-height: 290px !important;
        max-height: 290px !important;
  
        &>div {
          border-bottom: none !important;
  
          &>.autoCompleteWrapper {
            flex-direction: column-reverse !important;
          }
        }
      }
    }
  }

  .updateStatus__title {
    margin-bottom: 0px !important;
  }