.alert {
  z-index: 9999 !important;
  max-width: 700px;
  width: fit-content;
  min-width: 300px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;

  & .MuiAlert-icon {
    margin-right: 0;
    margin-left: 15px;
  }
}
