@import "../../../assets/styles/colors.module";

.switch {
  & .MuiFormControlLabel-root {
    margin-right: 0;
  }
}

.MuiSwitch-switchBase.Mui-checked {
  color: $turquoise !important;
}

.MuiSwitch-switchBase.Mui-checked.MuiSwitch-track {
  background-color: $turquoise !important;
}
