@import "../../../assets/styles/colors.module";

.dialogComponent {
  & > .MuiDialog-container {
    align-items: flex-start !important;
  }
}

.appDialog__cancelButton {
  background-color: $gray3 !important;
  border-color: $gray3 !important;
}
