.manageLockers__note {
    display: flex;
    justify-content: flex-end;
    color: red;
}

.manageLockers__wrapper {
    @media print {
      display: none;
    }
  }
  
  .manageLockers__printComponent {
    display: none;
    width: 100vw;
  
    @media print {
      display: initial;
  
      & > .manageLockers__cellToPrint {
        margin: 0 auto 30px;
  
        & > canvas {
          margin: 0 auto 10px;
          display: block;
        }
  
        & > span {
          font-weight: 600;
        }
      }
    }
  }

  .manageLockers{
    .tableContainer{
      max-height: calc(100vh - 400px);
    }
  }
  