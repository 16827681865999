.closetDisplay_dialog {

    &>.MuiDialog-container {

        &>.MuiPaper-root {
            min-width: 900px !important;
            max-width: 900px !important;

            &>.MuiDialogTitle-root {
                direction: rtl!important;
            }

            &>.MuiDialogContent-root {
                display: flex;
                justify-content: center;
            }
        }

    }
}

.closetDisplay_wrapper {
    position: relative;
}

.closetDisplay__wrapperCloset {
    display: flex;
    direction: ltr;
    margin-bottom: 20px;
    align-items: flex-end;
    float: left;
    margin: 54px;
    text-align: center;
}

.closetDisplay__widthValue {
    text-align: center;
}

.closetDisplay__heightCloset {
    position: absolute;
    left: 0px;
    background-color: black;
    width: 5px;
    direction: ltr;
    margin-top: 30px;
}


.closetDisplay__widthCloset {
    background-color: black;
    height: 5px;
    margin-bottom: 10px;
    margin-right: 58px;
}

.closetDisplay__heightValue {
    position: absolute;
    top: 50%;
    right: 13px;
}

.closetDisplay__cell {
    border: solid 2px black;
}

.closetDisplay__selectedCell {
    border: solid 2px black;
    background-color:  #E7EDF4;
    font-weight: 700;

}