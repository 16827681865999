@import "../../../assets/styles/colors.module";

.treeView {
  direction: initial !important;

  & .Mui-selected {
    background-color: transparent !important;
  }

  & .treeView__group {
    flex: 1;
    border-bottom: 2px solid $gray5;

    & > .MuiTreeItem-content {
      text-align: right;
      padding: 5px 8px;

      & .MuiTreeItem-label {
        font-weight: 600 !important;
        font-size: 1.2rem !important;
      }
    }
  }

  & .treeView__group--first {
    border-top: 2px solid $gray5;
  }

  & .treeView__child {
    flex: 1;

    & > .MuiTreeItem-content {
      text-align: right;
      padding: 5px 8px;

      & .MuiTreeItem-label {
        font-size: 1rem !important;
        padding-top: 3px;
      }
    }
  }

  .treeView__expandIcon {
    font-size: 1.8rem !important;
  }

  & .treeView__itemWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row-reverse;
  }
}
