@import "../../../../assets/styles/colors.module";

.closettDetails__isActiveSwitch {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 10px !important;

  &>.MuiFormControlLabel-root>.MuiSwitch-root {
    margin-right: -10px;
  }
}

.closettDetails__addCabinetButton {
  margin-bottom: 15px;
  margin-left: auto;
  display: block;
}

.closettDetails__closettWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  -webkit-box-shadow: 5px 5px 14px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 14px 1px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 10px;
  max-width: 1234px;
  margin-bottom: 15px;
}

.closettDetails__form {
  max-width: initial;
  flex: 1;

  &>form {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &>div:first-child {
      flex: 0 0 210px
    }

    &>* {
      max-width: 205px;
      min-width: 145px;
      width: initial !important;
      margin-left: 33px;
    }

    &>.css-pe0pe4-MuiFormControl-root {
      flex: 0.8;
    }

    &>.form__submitButton {
      margin-right: 80px;

    }
  }
}

.closettDetails__dialog {
  &>.MuiDialog-container {
    &>.MuiPaper-root {
      min-width: 500px !important;
      max-width: 500px !important;
    }
  }
}

.closettDetails__wrapperButtons {
  display: flex;

  &>u {
    flex: 0 0 81px;
    cursor: pointer;
  }

  &>strong {
    flex: 0 0 379px;
    display: flex;
    justify-content: flex-end;
  }
}

.closettDetails__wrapperColumn {
  width: 856px;
  height: 46px;
  background-color: #E7EDF4;
  display: flex;
  align-items: center;

  &>span {
    flex: 0 0 4%;
  }

  &>p {
    flex: 0 0 119px;
    margin: 0px !important
  }

  &>strong {
    flex: 0 0 83px;
  }

  &>button {
    cursor: pointer !important;
    margin-right: auto;

  }
}

.closettDetails__wrapperCells {
  border: solid 1px #E7EDF4;
  padding: 15px;
  width: 856px;

  &>u {
    cursor: pointer;
  }

}

.closettDetails__wrapperFormDetails {
  max-width: initial;
  flex: 1;

  &>form {
    display: flex;
    align-items: baseline;



    &>* {
      max-width: 180px;
      min-width: 140px;
      width: initial !important;
      margin-left: 59.5px;
    }
  }

}

.closettDetails__note {
  margin-right: 10px !important;
  color: red;
  text-align: right;
}

.closettDetails__dnd {
  flex: 0 0 31px;
}

.closettDetails__wrapperWidthIcon {

  &>div:first-child {
    font-size: larger;
  }

}

.closettDetails__wrapperElemnts {
  display: flex;
  justify-content: space-between;
  width: 95%;
  column-gap: 123px;
}

.closettDetails {
  width: 100%;
  overflow: auto;
  margin-bottom: 40px;
}

.closettDetails__deleteColumn {
  color: red;
  margin-right: auto;
  cursor: pointer;
}

.closettDetails__deleteCell {
  cursor: pointer;
}

.closettDetails__deleteCells {
  flex: 0 0 123px !important;
}

.closettDetails__disabledDnd {
  pointer-events: none;

  &>span {
    &>svg {
      opacity:0.3;
    }
  }
}