.statusDot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.statusDot__active {
  background-color: #009900;
}

.statusDot__inactive {
  background-color: #cc0000;
}

.statusDot__future {
  background-color: #0066c8;
}

.statusDot__canceled {
  background-color: #cc0000;
}