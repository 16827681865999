.legendItem {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.legendItem__icon {
  cursor: default !important;
}

.legendItem__stringSymbol {
}

.legendItem__description {
}
