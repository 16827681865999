.allocateBody {
    display: flex;
    width: 500px;
    flex-direction: column;
    .firstRow {
        display: flex;
        gap: 20px;
    }
    .secondRow {
        display: flex;
        gap: 20px
    }
}
.descendingHierarchyFormDialog {
    margin-top: 30px;
}