.tableButtons___wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.tableButtons__generalButtons {
  display: flex;
  align-items: center;
  height: 45.5px;

  & > * {
    height: 100%;
  }

  & > *:not(:first-child) {
    margin-right: 15px;
  }
}

.tableButtons__tableButtons {
  display: flex;
  align-items: center;
  height: 45.5px;

  & > * {
    height: 100%;
  }

  & > *:not(:last-child) {
    margin-left: 15px;
  }
}

.tableButtons__buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .button__exportCSV {
    text-decoration: none;
    height: 100%;
  }
}
