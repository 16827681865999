@import "../../../assets/styles/colors.module";

.manageCloset__addCompoundButton {
  margin-bottom: 15px;
  margin-left: auto;
  display: block;
}

.manageCloset__wrapperButton {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  max-width: 1392px;

  &>button {
    margin-left: initial;
  }
}

.manageCloset__subtitle {
  text-align: right;
  font-weight: 600;
  margin-bottom: 10px;
}

.manageCloset__compoundWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  -webkit-box-shadow: 5px 5px 14px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 14px 1px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 10px;
  max-width: 1392px;
  margin-bottom: 15px;
}

.manageCloset__wrapperField{
  width:150px !important
}

.manageCloset__form {
  max-width: initial;
  flex: 1;

  &>form {
    display: flex;
    align-items: center;
    justify-content: space-between;

    
    &>* {
      flex: 13% 0 0;
      max-width: 218px;
      min-width: 93px;
      justify-content: flex-end;
      width: initial !important;
    }

    &>fieldset {
      &>label {
        margin-left: 0px !important;
        margin-right: 0px !important;
      }
    }

    &>div:first-child {
      flex: 0 0 17%;
      padding-left: 32px;
    }

    & > label:first-child {
      margin-right: 2px;
      min-width: 165px;
    }

    & > .MuiTextField-root{
      margin-left: 30px;
    }

    &>.form__submitButton {
      margin: 0 10px;
     
    }

    &>.manageCloset__submitButton {
      margin: 0 10px;
      min-width: initial;
       flex:unset
    }
  }
}

.manageCloset__dialog {
  &>.MuiDialog-container {
    &>.MuiPaper-root {
      min-width: 500px !important;
      max-width: 500px !important;
    }
  }
}


.manageCloset__component {
  margin-left: 7px
}

.manageCloset__actions {
  width: 61px
}