.forgotPassword__wrapper {

  &>.appTitle {
    text-align: center;
    margin: 0px !important
  }

  &>form {
    &>.form__subtitle {
      text-align: center;
      margin-top: 0px !important;
      font-weight: 100 !important;
    }

    &>.form__button {
      margin-top: 15px;
      background-color: #358fb0 !important;
      color: white !important;
    }
  }

  .login__submitButton {
    margin-left: initial !important;
    display: initial !important;
    margin-top: 15px;
  }
}


.forgotPassword__title {
  text-align: center;
  margin-bottom: 0px !important
}

.forgotPassword__subtitle {
  text-align: center;
  margin-top: 0px !important
}

.forgotPassword__errorMessage{
  margin-top: 20px;
  color: red;
}