@import 'colors.module';
@import '../styles/variables';

@mixin no-outline {
  &:focus, &:active {
    outline: none;
  }
}

@mixin red-switcher {
  .rc-switcher-control-wrapper {
    .switcher-checkbox:checked + .switcher-indicator {
      background-color: $red;

      &:after {
        border-color: $red;
        background-image: none;
      }
    }
  }
}
