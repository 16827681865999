@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;800&display=swap");

* {
  font-family: "Open Sans", sans-serif !important;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiInputLabel-root {
  width: 100%;
  text-align: right;
}

.MuiInputLabel-root {
  transform-origin: top right !important;
}

.MuiInputLabel-root.MuiFormLabel-filled.MuiInputLabel-standard,
.MuiInputLabel-root.Mui-focused.MuiInputLabel-standard {
  transform: translate(0, 0) scale(0.75) !important;
  transform-origin: top right !important;
}

.MuiInputLabel-root.MuiFormLabel-filled.MuiInputLabel-outlined,
.MuiInputLabel-root.Mui-focused.MuiInputLabel-outlined {
  transform: translate(-14px, -10px) scale(0.75) !important;
  transform-origin: top right !important;
}

.MuiOutlinedInput-notchedOutline {
  text-align: initial !important;
}

.MuiFormHelperText-root {
  text-align: right !important;
}

.hideColumnSeperator > .MuiDataGrid-columnSeparator--sideRight {
  display: none !important;
}

.grecaptcha-badge {
  display: none;
}