@import "../../../assets//styles//colors.module";

.loaderWrapper {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(211, 211, 211, 0.493);
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  z-index: 2001;
}
.loader div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: $turquoise;
  animation: loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.loader div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.loader div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.loader div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes loader {
  0% {
    top: 8px;
    height: 64px;
  }
  // 50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
