.selectInput {
  & .MuiSelect-select.MuiSelect-standard.MuiInput-input {
    text-align: right;
    padding-right: 0 !important;
  }

  & .MuiSelect-icon {
    right: initial;
    left: 0;
  }
}

.MuiMenuItem-root {
  min-height: 36px !important;
}
