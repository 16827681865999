.userDetails__isActiveSwitch {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 10px !important;

  & > .MuiFormControlLabel-root > .MuiSwitch-root {
    margin-right: -10px;
  }
}

.userDetails__userSites {
  text-align: right;
  width: 100%;
  margin-bottom: 10px !important;

  & .MuiFormControlLabel-root {
    margin-right: 0 !important;
    margin-left: 10px;
  }
}

.userDetails__leadingRoleId-show {
  display: block;
}

.userDetails__leadingRoleId-hide {
  display: none;
}
