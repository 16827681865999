.manageClosetsWithSites__wrapper {
  @media print {
    display: none;
  }
}

.manageClosetsWithSites__printComponent {
  display: none;
  width: 100vw;

  @media print {
    display: initial;

    & > .manageClosetWithSites__cellToPrint {
      margin: 0 auto 30px;

      & > canvas {
        margin: 0 auto 10px;
        display: block;
      }

      & > span {
        font-weight: 600;
      }
    }
  }
}

.manageClosetsWithSites__printClosetsBarcodesDialog,
.manageClosetsWithSites__mobilyClosetDialog {
  @media print {
    display: none;
  }
}

.manageClosetsWithSites__mobilyClosetDialog {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.manageClosetsWithSiteList{
  .tableContainer{
    max-height: calc(100vh - 450px);
  }
}
