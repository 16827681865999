.customerList__eMail {
  flex: 2 !important;
}

.customersList{
  .tableContainer{
    max-height: calc(100vh - 450px);
  }
}

.manageReports__dotStatus {
  flex: initial !important;
  min-width: 40px !important;
  width: 40px !important;
}