@import "../../../assets/styles/colors.module";

.pricingPlans__formWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  -webkit-box-shadow: 5px 5px 14px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 14px 1px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 10px;
  max-width: 80vw;
  margin-bottom: 15px;
}

.pricingPlans__form {
  max-width: initial;
  flex: 1;

  & > form {
    display: flex;
    align-items: center;

    & > * {
      min-width: 80px;
      width: initial !important;
      min-height: 54px;
      margin-right: 2px;
    }

    & > div:first-child {
      flex: 0.9;
    }

    & > .form__submitButton {
      margin: 0 10px;
    }
    & > .pricingPlans__submitButton {
      margin: 0 10px;
      min-width: initial;
    }
    & > label {
      display: flex;
      justify-content: right;
      flex: 1;
      span {
        white-space: nowrap;
      }
    }
    & > label:first-child {
      flex: 0.4;
      text-align: right;
    }
    & > label:nth-child(4) {
      flex: 1.5;
    }
  }
}

.pricingPlans__button {
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
  margin-bottom: 15px;
  max-width: 80vw;
}

.pricingPlans__actions {
  width: 61px;
}
