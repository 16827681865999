.customerDetails__phoneWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > .customerDetails__phoneNumber {
    flex: 0.7;
  }

  & > .customerDetails__phoneExt {
    flex: 0.2;
    max-width: 130px;
  }
}


.customerDetails__generalSettingsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customerDetails__created {
  text-align: right;
  margin-bottom: 15px;
}