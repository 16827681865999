.mobilyCloset {
  min-width: 280px;

  .buttonComponent.btn {
    display: inline-block;
    min-width: 120px;

    @media (max-width: 600px) {
      width: 100%;
      margin: 20px auto 0;
    }
  }

  .mobilyCloset__buttonsWrapper {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    & > button {
      flex: 0.48;
      min-width: 120px;
    }
  }
}
