.MuiTablePagination-toolbar {
    align-items: self-end;
    margin-left: 80%;
    width: 20%;
    display: flex;
    direction: ltr;
}

.MuiTablePagination-actions {
    margin-bottom: .4rem;
}

.MuiTableCell-alignRight{
    padding: 16px 0px 16px 16px !important;
}

.MuiTableCell-stickyHeader{
    text-align: right !important;
}