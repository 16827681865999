@import "../../../assets/styles/index";
.listManagementWrapper {
  text-align: right;
  width: 550px;
  .title {
    margin-bottom: 50px;
  }

  .listNameWrapper {
    display: flex;
    justify-content: space-between;
    background-color: #ebe8e8;
    align-items: center;
    padding: 10px 20px;
    .listName {
      font-weight: bold;
    }
    .goToListOptionButton {
      border: none;
      background-color: transparent;
      text-decoration: underline;
      height: fit-content;
      padding: 0;
    }
  }

}
