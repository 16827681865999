.listOptionWrapper {
  text-align: right;
  width: 50%;
  margin-top: 50px;

  .listWrapper {
    margin-top: 50px;

    .listTitlesWrapper {
      display: flex;
      justify-content: space-between;
      padding: 0 30px;
      .listTitle {
        flex: 0 0 30%;
      }
      .buttonTitle {
        flex: 0 0 16%;
      }
    }
  }
}
