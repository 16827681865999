@import "../../../../assets/styles/colors.module";

.alertStackChild__wrapper {
  border-radius: 10px;
  padding: 8px 10px;
  min-width: 250px;
  background-color: #d32f2f;
  color: white;
  font-weight: 600;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
}

.alertStackChild__wrapper-rtl {
  direction: rtl;
}

.alertStackChild__wrapper-ltr {
  direction: ltr;
}

.alertStackChild__message {
  margin: 0 4px;
}

.alertStackChild__closeButton {
}
