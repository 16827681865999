.allocateBody,.report {
    display: flex;
    width: 600px;
    @media(max-width: 600px) {
        width: 90vw;
    }
    flex-direction: column;
    .firstRow {
        display: flex;
        gap: 20px;
        @media(max-width: 600px) {
            flex-direction: column;
        }
    }
    .secondRow {
        display: flex;
        gap: 20px;
        @media(max-width: 600px) {
            flex-direction: column;
        }

    }
}
.report {
    .reportRow {
        display: flex;
        flex-direction: row;
        gap: 30px;
        padding: 10px;
    }
    .blockedTitle{
        display: flex;
        align-items: center;
        .blockedNumber {
            margin-right: 3px;
            font-size: 14px;
        }
    }
}
