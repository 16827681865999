// @import "../../../../assets/styles/index";


.customerDetails__phoneWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    &>.customerDetails__phoneNumber {
      flex: 0.7;
    }
  
    &>.customerDetails__phoneExt {
      flex: 0.2;
      max-width: 130px;
    }
  }
  
  .changePassword__submitButton {
    margin-left: initial !important;
    display: initial !important;
    margin-top: 15px;
  }
  
  
  .changePassword__wrapper {
  
    &>.appTitle {
      text-align: center;
      margin: 0px !important
    }
    &>form {
      &>.form__subtitle {
        text-align: center;
        margin: 0px !important;
        font-weight: 100 !important;
      }
    }
  
    .login__submitButton {
      margin-left: initial !important;
      display: initial !important;
      margin-top: 15px;
    }
  }
  
  .changePassword_passwordInstructions {
    text-align: right;
    font-size: small;
    font-weight: 600;
  }
  
  .changePassword__title {
    text-align: center;
    margin-bottom: 0px !important
  }
  
  .changePassword__subtitle {
    text-align: center;
    margin-top: 0px !important
  }

  .changePassword__login{
    margin-top: 15px;
  }
  .changePassword__errorMessage{
    margin-top: 20px;
    color: red;
  }