.manageRoles__addRoleButton {
  margin-bottom: 15px;
  margin-left: auto;
  display: block;
}

.manageRoles__table {
  width: 100% !important;
  .tableWrapper .MuiDataGrid-columnHeadersInner > div[role=row] {
    width: auto !important;
  }

  .tableRow {
    display: flex !important;
  }

  .MuiDataGrid-virtualScrollerRenderZone {
    width: 100% !important;
    min-width: auto !important;
  }
}

.manageRoles__actionsTH {
  flex: 0.2 !important;
  min-width: initial !important;
}

.manageRoles__button{
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
  margin-bottom: 15px;
  max-width: 1002px;
}

.rolesList{
  .tableContainer{
    max-height: calc(100vh - 260px);
  }
  .MuiTableCell-alignRight{
    padding: 16px 16px 16px 16px !important;
  }

  .MuiTableCell-stickyHeader{
    padding-right: 16px !important;
  }
}