@import "../../../assets/styles/index";

.loginWrapper {
  .login__form {
    & .appTitle {
      text-align: center;
    }
  }
  
  .login__submitButton {
    margin-left: initial !important;
    display: initial !important;
    margin-top: 15px;
  }
}
