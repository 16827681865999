@import "../../../assets/styles/colors.module";

.buttonComponent {
  padding: 10px 25px !important;
  border-radius: 8px !important;
}

.buttonComponent__outlined {
  background-color: white !important;
  border: 2px solid $turquoise !important;
  color: $turquoise !important;
  box-sizing: border-box !important;
}

.buttonComponent__disabled {
  opacity: 0.65;
  cursor: default;
}
