@import "../../../assets/styles/colors.module";

.searchFiltersForm {
  flex: 1;
  max-width: initial;
  margin-bottom: 50px;

  & > form {
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  & .form__button {
    margin-top: 0;
  }
}

.filters__searchWrapper {
  display: flex;
  margin-left: 40px;
  flex-wrap: wrap;
  align-items: center;

  &.show {
    width: 100%;
  }

  & > *:not(.filters__searchIcon) {
    width: 200px !important;
    max-width: 200px;
  }

  & > * {
    margin-left: 25px !important;
  }

  & > .filters__searchIcon {
    height: 40px;

    &:hover {
      background-color: rgba(237, 247, 250, 0.86) !important;
    }

    & > .MuiSvgIcon-root {
      color: $turquoise;
    }
  }

  & > .filters__extendedButton {
    border: none;
    background-color: transparent;
    text-decoration: underline;
    display: block;
    text-align: right;
    width: fit-content !important;
  }

  & > .filters__extendedButton.show,
  .filters__searchIcon.show {
    display: flex;
  }
  & > .filters__extendedButton.hide,
  .filters__searchIcon.hide {
    display: none;
  }
}

.filters__extendedFieldsWrapper {
  & > .filters__extendedFieldsContainer {
    display: flex;
    flex-direction: column;
    background-color: $lightBlue;
    padding: 15px;
    border-radius: 8px;
    flex: 1;

    & > .filters__extendedFields {
      display: flex;
      flex: 1;
      align-items: center;
      flex-wrap: wrap;

      & > * {
        width: 200px;
        max-width: 200px;
      }

      & > * {
        margin-left: 25px;
      }
    }

    & > .filters__extendedActions {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & button[type="button"] {
        border: none;
        background-color: transparent;
        text-decoration: underline;
        margin-left: 10px;
      }

      & > .filters__submitButtonWrapper {
        display: flex;
        align-items: center;

        & button[type="button"] {
          margin-right: 10px;
        }
      }
    }
  }

  & > .filters__extendedFieldsContainer.hide {
    display: none;
  }

  & > .filters__extendedFieldsContainer.show {
    display: flex;
  }
}

.filters__extendedFieldsWrapper.hide {
  width: 1400px;

  @media (max-width: 1400px) {
    width: 100%;
  }
}
