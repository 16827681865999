.backButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  width: fit-content;
  margin-right: -4px;
  margin-bottom: 15px;
}

.backButton:hover {
  text-decoration: underline;
}