@import "../../../assets/styles/index";

.changeExpiredPassword__wrapper {

  &>.appTitle {
    text-align: center;
    margin: 0px !important
  }

  &>form {
    &>.form__subtitle {
      text-align: center;
      margin: 0px !important;
      font-weight: 100 !important;
    }
  }

  .login__submitButton {
    margin-left: initial !important;
    display: initial !important;
    margin-top: 15px;
  }
}


.changeExpiredPassword_passwordInstructions {
  text-align: right;
  font-size: small;
  font-weight: 600;
}

.changeExpiredPassword__title {
  text-align: center;
  margin-bottom: 0px !important
}

.changeExpiredPassword__subtitle {
  text-align: center;
  margin-top: 0px !important;
  margin-bottom: 30px;
}

.changeExpiredPassword__errorMessage{
  margin-top: 20px;
  color: red;
}