$white: white;
$black: black;
$fontColorGrey: #141414;
$fontColorGrayLight: #505070;
$darkerGray: #4A4A4A;
$gray1: #767575;
$gray2: #6D6D6D;
$gray3: #6E7379;
$gray4: #8B8B8B;
$gray5: #EAECEC;
$gray6: #737487;
$gray7: #D3D6D9;
$gray8: #DDDDDD;
$gray9: #979798;
$gray10: #E7EDF4;
$gray11: #52555F;
$gray12: #F0F1EB;
$gray13: #F3F3F3;
$gray14: #e5e5e5;
$gray15: #b5b9b2;
$gray16: #f6f7f8;
$gray17: #f5faff;
$gray18: #f0f0f0;
$gray19 : #F5F6FC;

$boxShadowGray01: rgba(112, 116, 121, 0.27);
$lightGray1: #F1F1F3;
$darkText: #1C2333;
$blueText: #2E384D;
$lightBlue:rgba(245, 250, 252, 0.747);
$red: #DC0008;
$blue: #004FA1;
$blue1: #0F59A7;
$blue2: #00739C;
$lighterBlue: #f0f8ff;
$green: #468600;
$green2: #4c7d00;
$lightGreen: #F6F7F2;
$backdrop-color: #F8F9F5;
$turquoise: #358fb0;


$file-upload-background: #F9FAFA;
$dark-grey: #343A40;
$dark-blue: #021652;
$blue-background: #F4F9FF;
$borderGray: #cccccc;
$borderGrayLight: #eeeeee;
$borderGrayLightBottom: #dfdfdf;


$grayText: #6B6B6B;
$darkTurquoise: #01CCAE;
$questionStripBlue: #F1F4F8;
$divider: #e1e1e1;
$turquoise1: #4B1D3F;

:export {
  purpleColor: $turquoise
}
