.pricingPlans__wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 700px;
    gap: 29px;
    &>.MuiFormControlLabel-root{
        margin-left: 0px !important;
        margin-top: 6px;
        &>span{
            width: 100px
        }
    }
}
.pricingPlans__cellSize{
   width:84px
}