.manageUsers__email {
  flex: 2 !important;
}


.manageUsers__note {
  display: flex;
  color: red;
}

.manageUsers__title {
  margin-bottom: 0px !important;
}

.usersList{
  .tableContainer{
    max-height: calc(100vh - 450px);
  }
}

.manageUsers__dotStatus {
  flex: initial !important;
  min-width: 40px !important;
  width: 40px !important;
}